import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the UnitRequestScreen state domain
 */

const selectUnitRequestScreenDomain = state =>
  state.unitRequestScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by UnitRequestScreen
 */

const makeSelectUnitRequestScreen = () =>
  createSelector(
    selectUnitRequestScreenDomain,
    substate => substate,
  );

export default makeSelectUnitRequestScreen;
export { selectUnitRequestScreenDomain };
