/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from './util/history';
// reducers
import GlobalVarReducer from './util/reducer';
import LoginScreenReducer from './screens/LoginScreen/reducer';
import ForgetPassScreenReducer from './screens/ForgetPassScreen/reducer';
import HomeScreenReducer from './screens/HomeScreen/reducer';
import ProfileScreenReducer from './screens/ProfileScreen/reducer';
import WelcomeScreenReducer from './screens/WelcomeScreen/reducer';
import FormProfileScreenReducer from './screens/FormProfileScreen/reducer';
import FormChangePassScreenReducer from './screens/FormChangePassScreen/reducer';
import NotificationScreenReducer from './screens/NotificationScreen/reducer';
import NotificationDetailScreenReducer from './screens/NotificationDetailScreen/reducer';
// end reducers
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function combinedReducers() {
const rootReducer = combineReducers({
    router: connectRouter(history),
    globalVar: GlobalVarReducer,
    loginScreen: LoginScreenReducer,
    forgetPassScreen: ForgetPassScreenReducer,
    homeScreen: HomeScreenReducer,
    welcomeScreen: WelcomeScreenReducer,
    formProfileScreen: FormProfileScreenReducer,
    formChangePassScreen: FormChangePassScreenReducer,
    notificationScreen: NotificationScreenReducer,
    notificationDetailScreen: NotificationDetailScreenReducer,
});

return rootReducer;
} 