export const commonStyle = {
    statusbarColor: '#F9FAFB',
    baseColor: '#F9FAFB',
    backgroundPage: '#ffeeb6',
    primaryColor: '#035397',
    cardBaseHeaderBg: '#D0E5FF',
    marginBottom: {
      marginBottom: 30
    },
    marginTop: {
      marginTop: 30
    },
    section: {
      paddingBottom: 30
    },
    noLeftSpace: {
      paddingLeft: 0,
      marginLeft: 0
    },
    cardShadow: {
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.1,
      shadowRadius: 2,
    },
    linkText:{
      color:'#004680',
    }
};