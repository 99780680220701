import React, { Component, useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectFormProfileScreen from './selector';
import reducer from './reducer';
import saga from './saga';

import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  FormControl,
  Input,
  TextArea,
  VStack, 
  Box, 
  Center,
  Text,
  Image, 
  Flex,
  ScrollView, 
  Button} from 'native-base';
import {FaUserCircle} from 'react-icons/fa';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { getPegawai, submitPegawai, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import { MdCalendarToday } from 'react-icons/md';
import DatePicker from "react-datepicker";
import moment from 'moment';

export function FormProfileScreen({
  dispatch,
  formProfileScreen,
  onUpdateInput,
  onGetPegawai,
  onSubmitPegawai
}){
  const[userData, setUserData] = useState();

  useEffect(() => {
      // const user = api.getUserData();
      // setUserData(user);
      // onUpdateInput('username', user.user_name);
      // onUpdateInput('email', user.email);
      // onGetPegawai();
  },[]);

  const handleSubmit = () => {
    if(helper.isEmpty(formProfileScreen.email) || helper.isEmpty(formProfileScreen.username)){
      helper.warningToast("Lengkapi semua data yang diperlukan");
      return;
    }
    onSubmitPegawai();
  }

  const InputDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(formProfileScreen.birth_date).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputRightElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} mr="2" color="muted.400" />} 
      />
  ));

  const onChangeDt = (selectedDate) => {
    const currentDate = selectedDate;
    onUpdateInput("birth_date", currentDate);
  };

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'profile', title: 'Ubah Profile'}}>
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
            <Flex>
              {/* <Box mt={4}>
                <Flex flexDirection="row" justifyContent="center" alignItems="center">
                  <Box shadow={3} borderColor="amber.100" borderRadius={20}>
                    <Image source={{
      uri: "https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
    }} borderRadius={20} alt='Room' size="lg"  />
                  </Box>
                  <Box ml={3}>
                    <Button variant="outline" colorScheme="primary" leftIcon={<Icon as={MaterialIcons} name="add-a-photo" size="sm" />}>Ganti Foto Profil</Button>
                  </Box>
                </Flex>
              </Box> */}
              <Box mx={3} mt={5} p={4} backgroundColor="white" shadow={3} borderRadius="lg">
                <HStack space={2}>
                  <Icon as={<Box><FaUserCircle/></Box>} size="5" mt="0.5" color="muted.400" />
                  <Text color="darkText" fontSize="md">
                    Profil
                  </Text>
                </HStack>
                <Box>
                  <VStack space={2} mt={2}>
                    <Box>
                      <FormControl mb="1" isRequired>
                        <FormControl.Label>NIK</FormControl.Label>
                        <Input value={formProfileScreen.nik} onChangeText={txt => onUpdateInput('nik', txt)} borderRadius="lg" />
                        {/* <FormControl.HelperText>
                          Give your project a title.
                        </FormControl.HelperText> */}
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl mb="1">
                        <FormControl.Label>Nama Lengkap</FormControl.Label>
                        <Input value={formProfileScreen.name} onChangeText={txt => onUpdateInput('name', txt)} borderRadius="lg" />
                        {/* <FormControl.HelperText>
                          Give your project a title.
                        </FormControl.HelperText> */}
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl mb="1">
                        <FormControl.Label>Kota Kelahiran</FormControl.Label>
                        <Input value={formProfileScreen.birth_city} onChangeText={txt => onUpdateInput('birth_city', txt)} borderRadius="lg" />
                        {/* <FormControl.HelperText>
                          Give your project a title.
                        </FormControl.HelperText> */}
                      </FormControl>
                    </Box>
                    <Box>
                    <FormControl mb="1" isRequired>
                          <FormControl.Label>Tanggal Lahir</FormControl.Label>
                          <DatePicker customInput={<InputDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={formProfileScreen.birth_date} onChange={(date) => onChangeDt(date)} />
                        </FormControl>
                    </Box>
                    <Box>
                      <FormControl mb="1" isRequired>
                        <FormControl.Label>Email</FormControl.Label>
                        <Input value={formProfileScreen.email} onChangeText={txt => onUpdateInput('email', txt)} borderRadius="lg" />
                        {/* <FormControl.HelperText>
                          Give your project a title.
                        </FormControl.HelperText> */}
                      </FormControl>
                    </Box>
                    
                    <Box>
                      <FormControl mb="1">
                        <FormControl.Label>No HP</FormControl.Label>
                        <Input value={formProfileScreen.no_hp} onChangeText={txt => onUpdateInput('no_hp', txt)} borderRadius="lg" />
                        {/* <FormControl.HelperText>
                          Give your project a title.
                        </FormControl.HelperText> */}
                      </FormControl>
                    </Box>
                    {/* <Box>
                      <FormControl mb="1">
                        <FormControl.Label>No Telp</FormControl.Label>
                        <Input value={formProfileScreen.no_phone} onChangeText={txt => onUpdateInput('no_phone', txt)} borderRadius="lg" />
                      </FormControl>
                    </Box> */}
                    <Box>
                      <FormControl mb="1">
                        <FormControl.Label>Alamat</FormControl.Label>
                        <TextArea h={20} value={formProfileScreen.address} onChangeText={txt => onUpdateInput('address', txt)} placeholder="Alamat" borderRadius="lg" w="100%" />
                      </FormControl>
                    </Box>
                  </VStack>
                </Box>
              </Box>
            </Flex>
            <Flex my={5} mx={3}>
              <Button onPress={() => handleSubmit()} isLoading={formProfileScreen.loading_submit} spinnerPlacement="end" isLoadingText="Submitting..." shadow={3}>Simpan</Button>
            </Flex>
          </ScrollView>
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  formProfileScreen: makeSelectFormProfileScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetPegawai: () => dispatch(getPegawai()),
    onSubmitPegawai: () => dispatch(submitPegawai()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(FormProfileScreen);