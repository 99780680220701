import {all} from 'redux-saga/effects';
// saga
import globalVarSaga from './util/saga';
import loginScreenSaga from './screens/LoginScreen/saga';
import forgetPassScreenSaga from './screens/ForgetPassScreen/saga';
import homeScreenSaga from './screens/HomeScreen/saga';
import profileScreenSaga from './screens/ProfileScreen/saga';
import welcomeScreenSaga from './screens/WelcomeScreen/saga';
import formProfileScreenSaga from './screens/FormProfileScreen/saga';
import formChangePassScreenSaga from './screens/FormChangePassScreen/saga';
import notificationScreenSaga from './screens/NotificationScreen/saga';
import notificationDetailScreenSaga from './screens/NotificationDetailScreen/saga';
// end saga
function* combinedSaga() {
  yield all([
    globalVarSaga(),
    loginScreenSaga(),
    forgetPassScreenSaga(),
    homeScreenSaga(),
    profileScreenSaga(),
    welcomeScreenSaga(),
    formProfileScreenSaga(),
    formChangePassScreenSaga(),
    notificationScreenSaga(),
    notificationDetailScreenSaga(),
  ]);
}
export default combinedSaga;